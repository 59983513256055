.home * {
  white-space: pre-wrap; }

.home div[data-swipeable] {
  overflow: hidden !important; }

.KDAE_CareFolderView .MuiFormHelperText-root {
  min-height: 1em; }

.country-label {
  color: white; }

.has-label .country-label {
  color: black; }

@media (max-width: 768px) {
  .country-label {
    color: black; }
  .has-label .country-label {
    color: black; } }
